import Vue from 'vue'

// 阿里云文件前缀
export const aLiOssPrefix = {

  gerber: 'gerber_',
  gerberImg: 'gerber-img_',
  bom: 'bom_',
  sysImg: 'sys-img_',
  sysVideo: 'sys-video_',
  sysPurchase: 'sys-purchase_',
  other: 'other_',
  otherVideo: 'other-video_',

}

// 全局状态汇总 
const enums = {
  aLiOssPrefix,
}

// 第一种方法: 集体导出 并注入到全局变量
window.enums = Vue.prototype.$enums = enums
export default enums