import Vue from 'vue'
import App from './App.vue'

import Vuex from 'vuex'
import router from './router'
import store from './store'

import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';

import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import '@wangeditor/editor/dist/css/style.css';
import { message } from '@/utils/message';

import '@/utils/enum-data';
import { downloadALi } from '@/utils/downloadALiOssFile';


Vue.use(Vuex);
Vue.use(ElementUI);
Vue.config.productionTip = false;
Vue.prototype.$message = message;
Vue.prototype.downloadALiFile = downloadALi;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')

router.onError((error) => {
  const pattern = /Loading .*failed/g  // vue2的正则
 //  const pattern = /Failed to fetch dynamically imported module*/g   // vue3的正则
  const isChunkLoadFailed = pattern.test(error);
  if (isChunkLoadFailed) {
  // 这里是用elementUI的组件库弹个提示  要改成自己项目使用的组件库
    ElementUI.Message({
      message: '系统已升级，正在刷新本地存储，请稍候...',
      type: 'warning',
      duration: 1500,
      offset: 60
    })
    location.reload()
  }
})
